import React from 'react';
import { createRoot } from 'react-dom/client';
import ChatBot from 'react-chatbotify';
import 'react-chatbotify/dist/style.css'; // Import default styles

const MyChatBot = () => {
    const [form, setForm] = React.useState({});

    const call_backend = async (params) => {
        try {
            // Prepare the payload to send to your backend
            const payload = {
                type: "site-chat",
                name: form.name,
                phone: form.phone,
                message: params.userInput,
            };

            // Make an HTTP POST request to the backend service
            const response = await fetch('https://msg.bythors.tech/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Include any necessary authentication headers
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Parse the JSON response from the backend
            const data = await response.json();

            // Assuming your backend returns the assistant's reply in data.message
            await params.injectMessage(data.message);
        } catch (error) {
            console.error('Error calling backend:', error);
            await params.injectMessage("Unable to get response from the server.");
        }
    };

    const settings = {
        general: {
            embedded: false
        },
        chatHistory: {
            storageKey: "bythors_history"
        },
        header: {
            title: "",
            showAvatar: true,
            avatar: "./assets/img/favicon.png"
        },
        chatButton: {
            //icon: "./assets/img/chat-icon.png"
            icon: ""
        },
        tooltip: {
            mode: "NEVER"
        }
    }

    const themes = [
        {id: "midnight_black", version: "0.1.0"}
    ]

    const styles = {
        chatButtonStyle: {
            background: "transparent",
            border: 'none',
            boxShadow: 'none',
            position: 'absolute',
            bottom: '11px',
            right: '11px',
            height: '45px',
            width: '45px',
            zIndex: 10,
        }
    }

    const clearNumber = (input) => {
        return input.replace(/\D/g, '');
    }

    const numberPath = (params) => {
        // Remove any non-digit characters
        const cleanedInput = clearNumber(params.userInput);
        // Check if the cleaned input has at least 7 digits
        if (cleanedInput.length >= 7) {
            return "ready"
        } else {
            return "ask_phone_again";
        }
    }

    const flow={
        start: {
            message: "Welcome to Bythors chat! Here we can chat about Bythors' services and technology. May I have your name, please?",
            function: (params) => setForm({
                ...form,
                name: params.userInput.charAt(0).toUpperCase() + params.userInput.slice(1).toLowerCase()
            }),
            path: "ask_phone"
        },
        ask_phone: {
            message: () => `Nice to meet you ${form.name}!  Could you please provide your phone number - with country code - so we can keep track of this conversation?`,
            function: (params) => setForm({...form, phone: clearNumber(params.userInput)}),
            path: (params) => numberPath(params)
        },
        ask_phone_again: {
            message: () => `That doesn't appear to be a valid phone number. Was there any typing error? Can you try again?`,
            function: (params) => setForm({...form, phone: clearNumber(params.userInput)}),
            path: (params) => numberPath(params)
        },
        ready: {
            message: "Thanks for the information! How can I help you today? Feel free to write in the language of your preference other than English.",
            path: "loop"
        },
        loop: {
            message: async (params) => {
                await call_backend(params);
            },
            path: "loop"
        }
    }
    return (
        <ChatBot settings={settings} themes={themes} styles={styles} flow={flow}/>
    );
};

export default MyChatBot;

const container = document.getElementById('chatbot');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<MyChatBot tab="home" />);